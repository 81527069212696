import { reactive, toRefs, getCurrentInstance, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { settleForm } from '@/common/form/SettleForm'
import { CommonFunction } from '@/common/CommonFunction.js'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters, dispatch } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    partyInfo: {},
    subscriberInfo: {},
    rewardPoint: 0,
    cardFee: 0,
    cashFee: 0,
    cashDiscount: 0,
    computedCashDiscount: computed(() =>
      state.selectedPayType === proxy.$ConstCode.PAY_TYPE['2'].value
        ? Math.floor(state.price * state.cashDiscount)
        : 0
    ),
    options: computed(() =>
      proxy.$Util.isEmpty(state.partyInfo)
        ? {}
        : ['reqKakao', 'reqPhone', 'reqEmail'].reduce((pv, cv) => {
            let tempObj = { ...pv }
            if (state.partyInfo[cv] == '1') {
              tempObj[cv.replace('req', 'res')] = ''
            }
            return tempObj
          }, {})
    ),
    price: computed(() => {
      // 1일당 금액 * 기간
      if (proxy.$Util.isEmpty(state.partyInfo)) return 0
      return state.partyInfo.acntTransferYn === 'N'
        ? Number(state.partyInfo.usePeriod) * Number(state.partyInfo.dailyPrice)
        : Number(state.partyInfo.dailyPrice)
    }),
    result: computed(() => {
      // price 에서 포인트 차감 금액
      let result = state.price
      if (!proxy.$Util.isEmpty(state.form.point) && Number(state.form.point)) {
        result -= state.form.point
      }
      return result
    }),
    fees: computed(() => {
      // price 에서 수수료 계산 금액
      if (proxy.$Util.isEmpty(state.partyInfo)) return 0
      if (state.selectedPayType === proxy.$ConstCode.PAY_TYPE['1'].value) {
        // 신용카드 결제
        return Math.floor(Number(state.cardFee) * state.price)
      }
      // 위즈캐시 결제
      return Math.floor(Number(state.cashFee) * state.price)
    }),
    chargeable: computed(() => state.subscriberInfo.cash < state.form.cash),
    inputPoint: '',
    form: {
      productNo: route.query.partyNo,
      cash: computed(() => {
        // 총 결제액 표시 금액
        if (state.selectedPayType === proxy.$ConstCode.PAY_TYPE['2'].value) {
          return state.result + state.fees - state.computedCashDiscount
        } else {
          return state.result + state.fees
        }
      }),
      productType: proxy.$ConstCode.PRODUCT_TYPE.PARTY.value,
      point: ''
    },
    selectedPayType: proxy.$ConstCode.PAY_TYPE['1'].value,
    settleForm: proxy.$_.merge({}, settleForm),
    isApplyPoint: computed(
      () =>
        proxy.$Util.isEmpty(state.form.point) || Number(state.form.point) < 1
    ),
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const { fnMyInfo, fnUpdateAuthType } = CommonFunction(state)
  const fnCallbackNice = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      // await router.push({ path: '/my-info/phone-auth' })
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  const fnSubscribe = async () => {
    const res = await proxy.$PartySvc.postPartySubscribe(route.query)
    if (res.resultCode === '0000') {
      state.partyInfo = res.partyInfo
      if (state.partyInfo.autoExtension === 'Y') {
        state.selectedPayType = proxy.$ConstCode.PAY_TYPE[2].value
      }
      state.subscriberInfo = res.subscriberInfo
      state.rewardPoint = res.rewardPoint
      state.cashDiscount = res.cashDiscount
      state.cardFee = res.cardFee
      state.cashFee = res.cashFee
    } else if (res.resultCode === '9999') {
      // 이미 마감된 파티
      alert(res.resultMsg)
      router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnApply = () => {
    // 입력을 하지 않거나, 0 ~ 100 사이인 경우
    if (
      proxy.$Util.isEmpty(state.inputPoint) ||
      Number(state.inputPoint) < 100
    ) {
      alert('100P 이상 사용 가능합니다.')
      return
    }
    // 입력값이 사용가능 포인트보다 많은 경우
    if (Number(state.inputPoint) > Number(state.subscriberInfo.point)) {
      alert('포인트가 부족합니다.')
      return
    }
    // 입력값이 결제액보다 많은 경우
    if (
      Number(state.inputPoint) >
      Number(state.price) - Number(state.computedCashDiscount)
    ) {
      alert('결제액(총 결제액 - 수수료) 이상 입력할 수 없습니다.')
      return
    }
    state.form.point = Number(state.inputPoint)
    alert('포인트가 적용되었습니다.')
  }
  const fnInitPoint = () => {
    state.inputPoint = ''
    state.form.point = ''
  }
  const fnCharge = () => {
    console.log('fnCharge')
  }
  const fnCancel = () =>
    router.push({
      name: 'party-view-index',
      query: route.query
    })
  const fnPay = async () => {
    if (
      state.options.resEmail &&
      !proxy.$Util.isValidEmail(state.options.resEmail)
    ) {
      return alert('올바른 이메일 주소를 등록해주세요.')
    }
    if (
      state.options.resPhone &&
      (!state.options.resPhone.startsWith('01') ||
        state.options.resPhone.length < 10)
    ) {
      return alert('올바른 휴대폰 번호를 등록해주세요.')
    }
    if (Object.values(state.options).some(o => proxy.$Util.isEmpty(o))) {
      return alert('구매자 요청 정보를 입력해주세요.')
    }
    const params = {
      ...state.form,
      ...state.options
    }
    params.cash -= state.fees
    params.mobile = proxy.$Util.isMobile()
    if (state.selectedPayType === proxy.$ConstCode.PAY_TYPE['1'].value) {
      if (Number(params.cash) < 100) {
        alert('100원 이하 상품은 캐시를 이용하여 구매해주세요.')
        return false
      }
    }
    if (proxy.$Util.isEmpty(state.userData.authType)) return
    if (
      !proxy.$Util.isEmpty(state.userData.authType) &&
      Number(state.userData.authType) < 2
    ) {
      alert('휴대폰 인증을 완료해 주세요.')
      await fnMyInfo()
      return false
    }
    if (state.selectedPayType === proxy.$ConstCode.PAY_TYPE['1'].value) {
      // 신용카드
      // alert(
      //   '결제 대행사의 문제로 카드 결제가 완료 된 후 꼭 완료 버튼을 눌러서 창을 닫아주세요.\n완료버튼을 누르지 않으시면 파티 참여가 되지 않습니다.'
      // )
      const res = await proxy.$CashSvc.postSettlePayload(params)
      if (res.resultCode === '9999') {
        alert(res.resultMsg)
        return router.push({
          name: 'party-view-index',
          query: {
            partyNo: route.query.partyNo
          }
        })
      }
      proxy.$_.merge(state.settleForm, res)
    } else if (state.selectedPayType === proxy.$ConstCode.PAY_TYPE['2'].value) {
      // 위즈캐시
      if (state.chargeable) {
        if (confirm('위즈 캐시가 부족합니다. 충전하시겠습니까?')) {
          return fnCashCharge()
        } else {
          return
        }
      }

      const res = await proxy.$CashSvc.postCashPayment(params)
      if (res.resultCode === '0000') {
        await router.push({
          path: '/party/done',
          query: {
            productNo: route.query.partyNo,
            poid: res.poid,
            productType: proxy.$ConstCode.PRODUCT_TYPE.PARTY.value
          }
        })
      } else {
        alert(res.resultMsg)
        return router.push({
          name: 'party-view-index',
          query: {
            partyNo: route.query.partyNo
          }
        })
      }
    }
  }
  const fnCallback = async query => {
    const { productNo, POid, isSuccess } = query
    if (isSuccess < 1) return dispatch('layout/removeAllModalAlertComponent')
    if (!proxy.$Util.isEmpty(productNo) && !proxy.$Util.isEmpty(POid)) {
      await router.push({
        path: '/party/done',
        query: {
          productNo: productNo,
          poid: POid,
          productType: proxy.$ConstCode.PRODUCT_TYPE.PARTY.value
        }
      })
    } else {
      setTimeout(() => {
        alert('결제 실패하였습니다.')
      }, 500)
    }
  }

  const maskingInputPoint = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value > Number(state.subscriberInfo.point)) {
      e.target.value = Number(state.subscriberInfo.point)
    }
    if (
      e.target.value >
      Number(state.price) - Number(state.computedCashDiscount)
    ) {
      e.target.value = Number(state.price) - Number(state.computedCashDiscount)
    }
    state.inputPoint = e.target.value
  }

  const fnCashCharge = () =>
    router.push({
      name: 'my-page-cash-charge-index',
      query: {
        from: 'party-pay-index',
        ...route.query
      }
    })

  const maskingResPhone = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 11) {
      e.target.value = e.target.value.substr(0, 11)
    }
    state.options.resPhone = String(e.target.value)
  }

  const maskingResKakao = e => {
    /* eslint-disable-next-line */
    e.target.value = e.target.value.replace(/[^A-Za-z0-9_\`\~\!\@\#\$\%\^\&\*\(\)\-\=\+\\\{\}\[\]\'\"\;\:\<\,\>\.\?\/\s]/gi, '')
    state.options.resKakao = e.target.value
  }

  const maskingResEmail = e => {
    /* eslint-disable-next-line */
    e.target.value = e.target.value.replace(/[^A-Za-z0-9_\`\~\!\@\#\$\%\^\&\*\(\)\-\=\+\\\{\}\[\]\'\"\;\:\<\,\>\.\?\/\s]/gi, '')
    state.options.resEmail = e.target.value
  }

  watch(
    () => state.selectedPayType,
    () => fnInitPoint()
  )

  const init = async () => {
    await fnSubscribe()
  }
  init()
  return {
    ...toRefs(state),
    fnCallbackNice,
    fnApply,
    fnInitPoint,
    fnCharge,
    fnCancel,
    fnPay,
    fnCallback,
    fnCashCharge,
    maskingResPhone,
    maskingResKakao,
    maskingResEmail,
    maskingInputPoint
  }
}
