<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>상품 결제 정보</span>
          </div>
          <div class="lump" v-if="!$Util.isEmpty(partyInfo)">
            <div class="table_type01">
              <dl>
                <dt>사용일</dt>
                <dd v-if="partyInfo.acntTransferYn === 'N'">
                  {{ `${partyInfo.usePeriod}일` }}
                </dd>
                <dd v-else>표시없음</dd>
              </dl>
              <dl>
                <dt>적립포인트</dt>
                <dd>
                  {{
                    Number(rewardPoint) === 0 || $Util.isEmpty(rewardPoint)
                      ? '이벤트없음'
                      : $Util.formatNumber2((rewardPoint * price).toFixed(0), {
                          unit: 'P'
                        })
                  }}
                </dd>
              </dl>
              <dl>
                <dt>판매가</dt>
                <dd>
                  {{ $Util.formatNumber2(price, { unit: '원' }) }}<br />
                  <span class="daily" v-if="partyInfo.acntTransferYn === 'N'"
                    >({{
                      $Util.formatNumber2(Number(partyInfo.dailyPrice), {
                        unit: '원'
                      })
                    }})</span
                  >
                </dd>
              </dl>
              <dl>
                <dt>수수료</dt>
                <dd>{{ $Util.formatNumber2(fees, { unit: '원' }) }}</dd>
              </dl>
            </div>
            <div
              v-if="partyInfo.autoExtension === 'Y'"
              class="default"
              style="padding: 1rem 0 0 0;"
            >
              <div class="detailwrap note attention auto_background">
                <!-- 211208자동연장파티 추가 -->
                <dl>
                  <dt>
                    <div class="auto_font">자동 연장 파티 참여 안내</div>
                  </dt>
                  <dd>
                    <div>
                      * 자동연장 파티는 위즈캐시를 통한 결제만 가능합니다.
                      <br />
                      * 판매자가 중간에 자동연장을 해제 할 경우 별도의 알림이
                      발송되며, 연장이 자동 해제됩니다. <br />
                      * 자동연장 전 위즈캐시 충전이 진행되어야 하며, 자동연장 시
                      위즈캐시가 부족할 경우 참여가 불가능합니다. <br />
                      * 자동연장 해지 시 마이페이지 > 참여파티 > 자동연장파티
                      선택 > “연장해제”를 통해 가능합니다.
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="table_type02">
              <dl>
                <dt>파티명</dt>
                <dd>{{ partyInfo.title }}</dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ subscriberInfo.name }}</span>
                  <span class="name">{{ subscriberInfo.nick }}</span>
                  <!--                  <span class="name">{{ subscriberInfo.name }}</span>-->
                  <!--                  <span>{{ subscriberInfo.nick }}</span>-->
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          Number(subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                      },
                      {
                        naver:
                          Number(subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                      },
                      {
                        google:
                          Number(subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                      },
                      {
                        apple:
                          Number(subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                      }
                    ]"
                    v-if="!$Util.isEmpty(subscriberInfo.login_type)"
                  >
                    {{
                      Number(subscriberInfo.login_type) === 0
                        ? '카카오'
                        : Number(subscriberInfo.login_type) === 1
                        ? '네이버'
                        : Number(subscriberInfo.login_type) === 2
                        ? '구글'
                        : '애플'
                    }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>보유중인 캐시</dt>
                <dd style="display: flex; align-items: center;">
                  <div>
                    <span class="color">{{
                      $Util.formatNumber2(subscriberInfo.cash, { unit: '' })
                    }}</span>
                    C
                  </div>
                  <button class="btn_wizzcash">
                    <div @click="fnCashCharge" class="btn_text">충전하기</div>
                  </button>
                </dd>
              </dl>
              <dl>
                <dt>보유중인 포인트</dt>
                <dd>
                  <span class="color">{{
                    $Util.formatNumber2(subscriberInfo.point, { unit: '' })
                  }}</span>
                  P
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="!$Util.isEmpty(options)" class="lump">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                파티원 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="partyInfo.reqKakao == '1'" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        @input="maskingResKakao"
                        :value="options.resKakao"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="partyInfo.reqPhone == '1'" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요(숫자만)"
                        @input="maskingResPhone"
                        :value="options.resPhone"
                        :maxlength="11"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="partyInfo.reqEmail == '1'" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        @input="maskingResEmail"
                        :value="options.resEmail"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="blue_line" style="margin-bottom: 0;">
            <div class="inner">
              <div>판매가</div>
              <div>{{ $Util.formatNumber2(price, { unit: ' 원' }) }}</div>
            </div>
            <div class="form">
              <div class="form_in">
                <div class="inline">
                  <label class="input">
                    <input
                      type="number"
                      :value="inputPoint"
                      @input="maskingInputPoint"
                      :min="0"
                      inputmode="decimal"
                      pattern="\d*"
                      placeholder="사용할 포인트 입력"
                      :disabled="
                        !($Util.isEmpty(form.point) || Number(form.point) < 1)
                      "
                    />
                  </label>
                  <button
                    class="btn"
                    :class="{
                      bo: $Util.isEmpty(inputPoint) || Number(inputPoint) < 100
                    }"
                    @click="fnApply"
                    v-if="$Util.isEmpty(form.point) || Number(form.point) < 1"
                  >
                    사용</button
                  ><button class="btn bo" @click="fnInitPoint" v-else>
                    취소
                  </button>
                </div>
                <div class="hint">* 100P 단위 이상 사용</div>
              </div>
            </div>
            <div class="inner">
              <div>수수료</div>
              <div>{{ $Util.formatNumber2(fees, { unit: ' 원' }) }}</div>
            </div>
            <div class="inner plain">
              <div>포인트 사용</div>
              <div>
                {{ $Util.formatNumber2(form.point || 0, { unit: ' 원' }) }}
              </div>
            </div>
            <div
              v-if="selectedPayType === $ConstCode.PAY_TYPE['2'].value"
              class="inner plain"
            >
              <div>캐시 할인</div>
              <div>
                {{ $Util.formatNumber2(computedCashDiscount, { unit: ' 원' }) }}
              </div>
            </div>
            <div class="inner result">
              <div>총 결제액</div>
              <div>{{ $Util.formatNumber2(form.cash, { unit: ' 원' }) }}</div>
            </div>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <!--                <li class="form_li">-->
                <!--                  <div class="form_ttl">포인트 사용</div>-->
                <!--                  <div class="form_in">-->
                <!--                    <div class="inline">-->
                <!--                      <label class="input">-->
                <!--                        <input-->
                <!--                          type="number"-->
                <!--                          style="text-align: right;"-->
                <!--                          v-model="inputPoint"-->
                <!--                          :min="0"-->
                <!--                          inputmode="decimal"-->
                <!--                          pattern="\d*"-->
                <!--                        />-->
                <!--                      </label>-->
                <!--                      <button class="btn" @click="fnApply">적용</button>-->
                <!--                    </div>-->
                <!--                    <div class="hint">* 100P 단위 이상 사용</div>-->
                <!--                  </div>-->
                <!--                </li>-->
                <li class="form_li">
                  <div class="form_ttl">결제 방법 선택</div>
                  <div class="form_in">
                    <div class="inline">
                      <label
                        v-if="partyInfo.autoExtension === 'Y'"
                        class="select"
                        style="flex: 1;"
                      >
                        <select
                          class="disabled"
                          disabled
                          v-model="selectedPayType"
                        >
                          <option :value="$ConstCode.PAY_TYPE[2].value">{{
                            $ConstCode.PAY_TYPE[2].name
                          }}</option>
                        </select>
                      </label>
                      <label v-else class="select" style="flex: 1;">
                        <select v-model="selectedPayType">
                          <option
                            :value="item.value"
                            v-for="(item, index) in Object.values(
                              $ConstCode.PAY_TYPE
                            )"
                            :key="index"
                            >{{ item.name }}</option
                          >
                        </select>
                      </label>
                      <!--                      <button-->
                      <!--                        class="btn"-->
                      <!--                        @click="fnCharge"-->
                      <!--                        v-if="-->
                      <!--                          selectedPayType === $ConstCode.PAY_TYPE['2'].value-->
                      <!--                        "-->
                      <!--                      >-->
                      <!--                        캐시 충전-->
                      <!--                      </button>-->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!--          <div class="blue_line">-->
          <!--            <div class="inner plain">-->
          <!--              <div>결제금액</div>-->
          <!--              <div>-->
          <!--                <span>{{-->
          <!--                  $Util.formatNumber2(price + fees, { unit: '' })-->
          <!--                }}</span>-->
          <!--                원-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="inner plain">-->
          <!--              <div>할인</div>-->
          <!--              <div>-->
          <!--                <span>{{ $Util.formatNumber2(form.point, { unit: '' }) }}</span>-->
          <!--                P-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="inner">-->
          <!--              <b>총 결제액</b>-->
          <!--              <b>-->
          <!--                <span class="color">{{-->
          <!--                  $Util.formatNumber2(form.cash, { unit: '' })-->
          <!--                }}</span>-->
          <!--                원-->
          <!--              </b>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnCancel">취소</button>
              <button type="button" class="btn" @click="fnPay">결제하기</button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>주의사항</dt>
                <dd>
                  <div>
                    * 수수료는 플랫폼이용료(7%)와 카드수수료(3.3%)를 합한
                    금액입니다.
                  </div>
                  <div>
                    - 위즈캐시 결제 시 플랫폼 이용료 5% 적용 및 카드수수료 면제
                  </div>
                  <div>
                    * 개별 공급자가 등록한 나눔 내용 및 거래에 대한 책임은
                    공급자가 부담하며, 이에 따라서 공급자가 진행하는 서비스에
                    대해서 위즈니는 어떠한 책임과 의무를 지지 않습니다.
                  </div>
                  <div>
                    * 파티장 연락두절 및 이용 불가능한 상태 방치 등에 의한
                    환불인 경우 위즈니에서 남은 기간에 대한 환불을 보장하며,
                    위즈캐시로 환불 진행됩니다.
                  </div>
                  <div>
                    (단 참여 후 1일이 경과되지 않은 상태에서 파티장 과실에 의한
                    사용 불가가 확인 된 경우 100%금액 현금 환불)
                  </div>
                  <div class="sec_color">
                    * 양도 거래시에 발생되는 분쟁은 당사자간에 해결이 필요하며,
                    위즈니는 책임을 지지 않습니다. 반드시 계정 양도에 적합한
                    상품인지 확인하고 거래해주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <settle v-bind:="settleForm" :callback="fnCallback" />
      <nice
        :id="'formNice6'"
        :m="niceForm.m"
        :encode-data="niceForm.encodeData"
        :call-type="niceForm.callType"
        :rtn-url="rtnUrl"
        :fail-rtn-url="failRtnUrl"
        :callback="fnCallbackNice"
      />
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import Settle from '@/components/common/settle/index.vue'

export default {
  name: 'party-pay-index',
  components: { Settle },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
