export const settleForm = {
  pamt: '',
  pcancPUrl: '',
  pename: '',
  pgoods: '',
  pmid: '',
  pmname: '',
  pnextPUrl: '',
  pnoteUrl: '',
  poid: '',
  puname: '',
  settleUrl: ''
}
