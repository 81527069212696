<template>
  <form :action="settleUrl" method="post" id="formSettle" :target="target">
    <input type="hidden" name="PAmt" :value="pamt" />
    <input type="hidden" name="PMid" :value="pmid" />
    <input type="hidden" name="PGoods" :value="pgoods" />
    <input type="hidden" name="POid" :value="poid" />
    <input type="hidden" name="PMname" :value="pmname" />
    <input type="hidden" name="PEname" :value="pename" />
    <input type="hidden" name="PUname" :value="puname" />
    <input type="hidden" name="PNoteUrl" :value="pnoteUrl" />
    <input type="hidden" name="PCancPUrl" :value="pcancPUrl" />
    <input type="hidden" name="PNextPUrl" :value="pnextPUrl" />
  </form>
</template>

<script>
import { watch, getCurrentInstance } from 'vue'
import { openSettlePop } from '@/common/GlobalFunction'
import { fnModalAlert } from '@/components/common/modal/alert'

export default {
  name: 'component-settle-index',
  props: {
    target: {
      type: [String],
      default: 'settle-bank-popup'
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    pamt: {
      type: [String],
      default: ''
    },
    pcancPUrl: {
      type: [String],
      default: ''
    },
    pename: {
      type: [String],
      default: ''
    },
    pgoods: {
      type: [String],
      default: ''
    },
    pmid: {
      type: [String],
      default: ''
    },
    pmname: {
      type: [String],
      default: ''
    },
    pnextPUrl: {
      type: [String],
      default: ''
    },
    pnoteUrl: {
      type: [String],
      default: ''
    },
    poid: {
      type: [String],
      default: ''
    },
    puname: {
      type: [String],
      default: ''
    },
    settleUrl: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const fnModalAlertSettleBankPopup = async () => {
      const payload = {
        component: proxy.$modalAlertNames.SETTLE_BANK_POPUP,
        data: {
          target: props.target
        },
        callback: modalStatus => {
          if (typeof modalStatus == 'string') {
            document.getElementById('formSettle').submit()
            openSettlePop(props.callback)
          }
        }
      }

      await fnModalAlert(payload)
    }

    watch(
      () => props.poid,
      async () => {
        await fnModalAlertSettleBankPopup()
      }
    )
    return { ...props }
  }
}
</script>

<style scoped></style>
